import { ButtonProps } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";
import { ReactNode } from "react";

export type PopupAction = {
  name: string;
  className?: string;
  color?: ButtonProps["color"];
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
  isLoading?: boolean;
  onClick: () => void | Promise<void>;
  disableCloseAfterAction?: boolean;
};

interface IInitialState {
  show: boolean;
  opening: boolean;
  options: {
    headerTitle?: string;
    title?: ReactNode;
    content: ReactNode;
    lottie?: ReactNode;
    actions?: PopupAction[];
    nonClosable?: boolean;
    handleCloseOverride?: () => void;
  };
}

const initialState: IInitialState = {
  show: false,
  opening: false,
  options: {
    headerTitle: "",
    content: "",
  },
};

const popupSlice = createSlice({
  name: "shared/popup",
  initialState,
  reducers: {
    showPopup: (state, { payload }: PayloadAction<IInitialState["options"]>) => {
      state.show = true;
      state.opening = true;
      state.options = {
        ...initialState.options,
        ...payload,
      };
    },
    hidePopup: (state) => {
      state.show = false;
    },
    closePopup: (state) => {
      state.opening = false;
    },
    setPopupOptions: (state, { payload }: PayloadAction<Partial<IInitialState["options"]>>) => {
      state.options = { ...state.options, ...payload };
    },
    updateActionLoading: (
      state,
      { payload }: PayloadAction<Pick<PopupAction, "name" | "isLoading">>,
    ) => {
      const action = (state.options.actions ?? [])?.find((a) => a.name === payload.name);

      if (action) {
        action.isLoading = payload.isLoading;
      }
    },
  },
});

export const selectSharedLayoutPopup = ({ shared }: RootState) => shared.layout.popup;

export const { showPopup, hidePopup, closePopup, setPopupOptions, updateActionLoading } =
  popupSlice.actions;

export default popupSlice.reducer;
