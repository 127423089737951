import { Drawer, useTheme } from "@mui/material";
import { getProfile, selectProfile } from "components/dashboard/store/profileSlice";
import { LoadingSpinner } from "components/shared";
import { usePageType } from "components/shared/layout/hooks";
import { showMessage } from "components/shared/layout/store/messageSlice";
import { useScrollLock, useStorageChange } from "hooks";
import { isError } from "lib/axios/error";
import { useAppDispatch, useAppSelector } from "lib/redux";
import pick from "lodash/pick";
import { referralKeys } from "models/dashboard/consumer/referral";
import { IUser, userKeys } from "models/dashboard/shared";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useRef, useState } from "react";
import { createManyReferrals } from "rest-api/referral";
import { updateUserProfile } from "rest-api/user/settings";
import {
  selectQuestionnaireFormValues,
  selectSocialRegisterFormValues,
} from "./register/consumer/store/stateSlice";

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const {
    data: { id, role, firstName, lastName, phone, locations, suburbs, professional },
    meta: { requestStatus },
  } = useAppSelector(selectProfile);
  const questionnaireValues = useAppSelector(selectQuestionnaireFormValues);
  const socialRegisterFormValues = useAppSelector(selectSocialRegisterFormValues);

  const theme = useTheme();
  const { pathname, push, replace } = useRouter();

  const { key, value } = useStorageChange();
  const page = usePageType();
  const mainRoutes = page === "main";
  const authRoutes = page === "auth";
  const isProfileLoading = useRef(false);
  const isRedirectingToLogin = useRef(false);
  const [redirectedToLogin, setRedirectedToLogin] = useState(true);
  const [redirectedToProfile, setRedirectedToProfile] = useState(true);

  const [logged, setLogged] = useState(false);

  useEffect(() => {
    if (key === "logged" && value === "1") {
      setLogged(true);
    }
  }, [key, value]);

  useEffect(() => {
    if (authRoutes) {
      setRedirectedToLogin(true);
      isRedirectingToLogin.current = false;
    }
  }, [authRoutes]);

  useEffect(() => {
    if (isRedirectingToLogin.current || isProfileLoading.current) return;

    if (localStorage.getItem("loggingOut") === "1") {
      localStorage.removeItem("loggingOut");
      return;
    }

    if (
      requestStatus === "initial" ||
      (!authRoutes &&
        !mainRoutes &&
        requestStatus === "rejected" &&
        !isRedirectingToLogin.current) ||
      logged
    ) {
      isProfileLoading.current = true;
      setLogged(false);
      localStorage.removeItem("logged");

      dispatch(getProfile()).then(async ({ payload }) => {
        if (isError(payload) && !authRoutes && !mainRoutes) {
          if (localStorage.getItem("loggingOut") !== "1") {
            setRedirectedToLogin(false);
            isRedirectingToLogin.current = true;
            dispatch(
              showMessage({ message: payload.error.message ?? "Unauthorized", variant: "warning" }),
            );
            replace("/auth/login");
          } else {
            setRedirectedToLogin(false);
            isRedirectingToLogin.current = true;
            dispatch(
              showMessage({
                message: "You have been successfully logged out.",
                variant: "success",
              }),
            );
            location.href = "/auth/login";
          }
        } else {
          const user = (payload as { data: IUser | undefined })?.data;
          if (user?.id && authRoutes) {
            let consumerRedirectPath;
            if (!user.questionnaireComplete && questionnaireValues) {
              await updateUserProfile(
                pick(
                  {
                    ...questionnaireValues,
                    ...socialRegisterFormValues,
                  },
                  userKeys,
                ),
              );
              await dispatch(getProfile());

              const referralValues = pick(questionnaireValues, referralKeys) as Parameters<
                typeof createManyReferrals
              >[0];

              if (referralValues.experts.length) {
                const result = await createManyReferrals(referralValues);
                if (!isError(result)) {
                  const referrals = result;
                  const potentialPros = referrals[0]?.potentiallyMatchedProfessionals;
                  if (!referrals.length) {
                    consumerRedirectPath = "/buyer-owner/tools";
                  } else if (referrals.length === 1 && potentialPros && potentialPros.length > 0) {
                    consumerRedirectPath = `/buyer-owner/experts/find-experts/select/${referrals[0].id}`;
                  } else {
                    consumerRedirectPath = "/buyer-owner/experts/your-experts";
                  }
                }
              }
            }

            if (user.role.name === "Consumer") {
              if (consumerRedirectPath) {
                replace(consumerRedirectPath);
              } else {
                replace("/buyer-owner/experts/your-experts");
              }
            } else if (user.role.name === "Professional") {
              replace("/professional/referrals-board");
            }
          }
        }

        isProfileLoading.current = false;
        localStorage.removeItem("loggingOut");
      });
    }
  }, [
    authRoutes,
    dispatch,
    logged,
    mainRoutes,
    questionnaireValues,
    replace,
    requestStatus,
    socialRegisterFormValues,
  ]);

  const isRedirectingToProfile = useRef(false);

  useEffect(() => {
    setRedirectedToProfile(true);
    isRedirectingToProfile.current = false;
  }, [pathname]);

  useEffect(() => {
    if (id && authRoutes) {
      if (role.name === "Consumer") {
        replace("/buyer-owner/experts/your-experts");
      } else if (role.name === "Professional") {
        replace("/professional/referrals-board");
      }
    }
  }, [authRoutes, id, replace, role.name]);

  useEffect(() => {
    if (role.name === "Consumer" && page === "professional") {
      replace("/buyer-owner/experts/your-experts");
    } else if (role.name === "Professional" && page === "buyer-owner") {
      replace("/professional/referrals-board");
    }
  }, [page, replace, role.name]);

  useEffect(() => {
    if (
      requestStatus === "fulfilled" &&
      (page === "buyer-owner" || page === "professional") &&
      (!firstName ||
        !lastName ||
        !phone ||
        !locations?.length ||
        !suburbs?.length ||
        (role.name === "Professional" && !professional?.job)) &&
      !isRedirectingToProfile.current
    ) {
      if (role.name === "Consumer" && !pathname.startsWith("/buyer-owner/settings")) {
        push("/buyer-owner/settings");
        setRedirectedToProfile(false);
        isRedirectingToProfile.current = true;
      } else if (role.name === "Professional" && !pathname.startsWith("/professional/settings")) {
        push("/professional/settings");
        setRedirectedToProfile(false);
        isRedirectingToProfile.current = true;
      }
    }
  }, [
    firstName,
    lastName,
    locations,
    page,
    pathname,
    phone,
    professional?.job,
    push,
    requestStatus,
    role,
    suburbs,
  ]);

  const isLoading = !!(
    (!mainRoutes && requestStatus === "pending") ||
    (authRoutes && id) ||
    (role.name === "Consumer" && page === "professional") ||
    (role.name === "Professional" && page === "buyer-owner") ||
    !redirectedToLogin ||
    !redirectedToProfile
  );

  const [lockScroll, unlockScroll] = useScrollLock();

  useEffect(() => {
    if (isLoading) lockScroll();
    else unlockScroll();
  }, [isLoading, lockScroll, unlockScroll]);

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="bottom"
        open={isLoading}
        ModalProps={{ keepMounted: false }}
        classes={{ paper: "bg-transparent backdrop-blur-2xl" }}
        sx={{
          zIndex: theme.zIndex.appBar,
          "& .MuiPaper-root": {
            position: "fixed",
            inset: 0,
          },
        }}
      >
        <div className="transform-center absolute">
          <LoadingSpinner className="text-primary-main" size="4rem" />
        </div>
      </Drawer>
      {children}
    </>
  );
};

export default AuthProvider;
