import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IYourExpertsForBuyingContent } from "models/dashboard/consumer/your-experts";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerForBuyingContentReq } from "rest-api/dashboard/consumer/your-experts";

export const getConsumerForBuyingContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/ypb/forBuying/content/getConsumerForBuyingContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerForBuyingContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerForBuyingContent = ({ dashboard }: RootState) =>
  dashboard.consumer.ypb.forBuying;

const initialState: IYourExpertsForBuyingContent = {
  forBuying: [],
};

const contentSlice = createSlice({
  name: "dashboard/consumer/ypb/forBuying/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerForBuyingContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IYourExpertsForBuyingContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
