import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IReportProblemContent } from "models/dashboard/consumer/report-problem";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerReportProblemContentReq } from "rest-api/dashboard/consumer/report-problem";

export const getConsumerReportProblemContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/reportProblem/content/getConsumerReportProblemContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerReportProblemContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerReportProblemContent = ({ dashboard }: RootState) =>
  dashboard.consumer.reportProblem.content;

const initialState: IReportProblemContent = {
  title: "",
  content: "",
  form: { fields: [], submitButtonText: "" },
  contactUsButton: {
    path: "",
    text: "",
  },
  successSection: {
    icon: { url: "", alternativeText: "" },
    content: "",
    buttons: [],
  },
};

const contentSlice = createSlice({
  name: "dashboard/consumer/reportProblem/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerReportProblemContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IReportProblemContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
