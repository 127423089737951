import { IForm, ISelectOption } from "models/form";
import { IReview, IUser, IUserTransformed } from "../shared";

export const referralKeys = ["experts", "expertQuestions"] as const;

export interface IReferral {
  id: string;
  job: string;
  status: "unmatched" | "matched" | "searching" | "ended";
  expertQuestions: IForm;
  createdAt: string;
  buyerSeller?: IUser;
  professional?: Partial<IUser>;
  acquisitionStatus: "new" | "active" | "converted" | "closed";
  relatedReferrals?: { id: string }[];
  potentiallyMatchedProfessionals?: IUser[];
  review?: IReview;
}

export interface IReferralTransformed
  extends Omit<IReferral, "job" | "expertQuestions" | "buyerSeller" | "professional"> {
  job: ISelectOption;
  expertQuestions: { [x: string]: string | number | ISelectOption | ISelectOption[] | null };
  buyerSeller?: IUserTransformed;
  professional?: Partial<IUserTransformed>;
}
