import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSharedLayout } from "components/shared/layout/store/layoutContentSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { AppDispatch, RootState } from "lib/redux";
import merge from "lodash/merge";
import { ConsumerDashboardLayout } from "models/dashboard/consumer/layout";
import { HYDRATE } from "next-redux-wrapper";
import {
  getConsumerDashboardLayoutReq,
  getConsumerDashboardMenusReq,
} from "rest-api/dashboard/consumer/layout-settings";
import { getConsumerReportProblemContent } from "../../report-problem/store/contentSlice";

export const getConsumerDashboardLayout = createAsyncThunk<
  typeof initialState,
  void,
  { dispatch: AppDispatch; rejectValue: ErrorResult }
>(
  "dashboard/consumer/layout/content/getConsumerDashboardLayout",
  async (_, { dispatch, rejectWithValue }) => {
    await dispatch(getSharedLayout());
    await dispatch(getConsumerReportProblemContent());

    const menuData = await getConsumerDashboardMenusReq();

    if (isError(menuData)) {
      return rejectWithValue(menuData);
    }

    const contentData = await getConsumerDashboardLayoutReq();

    if (isError(contentData)) {
      return rejectWithValue(contentData);
    }

    return merge(menuData, contentData);
  },
);

export const selectConsumerDashboardLayoutContent = ({ dashboard }: RootState) =>
  dashboard.consumer.layout.content;

const initialState: ConsumerDashboardLayout = {
  nav: { items: [] },
  mobileNav: { items: [] },
  userMenu: { items: [] },
  expertsNav: { items: [] },
};

const layoutContentSlice = createSlice({
  name: "dashboard/consumer/layout/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerDashboardLayout.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<typeof initialState, typeof HYDRATE>) => action.payload,
      );
  },
});

export default layoutContentSlice.reducer;
