import { combineReducers } from "@reduxjs/toolkit";
import content from "./contentSlice";
import leaveReviewContent from "./leaveReviewContentSlice";
import leaveReviewState from "./leaveReviewStateSlice";
import profile from "./profileSlice";
import reportProfileContent from "./reportProfileContentSlice";
import reviewsFilterState from "./reviewsFilterStateSlice";

const reducer = combineReducers({
  content,
  profile,
  reviewsFilterState,
  leaveReviewContent,
  leaveReviewState,
  reportProfileContent,
});

export default reducer;
