import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IProfessionalReferralsBoardContent } from "models/dashboard/professional/referrals-board";
import { formPopulate } from "models/form";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getProfessionalReferralsBoardContentReq = () => {
  return axiosInstance
    .get(`/api/professional-referrals-board`, {
      params: {
        populate: {
          tabs: { populate: { icon: imagePopulate } },
          colors: true,
          referralItem: { populate: { sections: { populate: "*" }, buttons: true } },
          states: { populate: { icon: imagePopulate } },
          ...formPopulate,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IProfessionalReferralsBoardContent>)
    .then((data) => transformForm(data, ["form"]))
    .catch(getError);
};
