import { combineReducers } from "@reduxjs/toolkit";
import expertProfile from "../expert-profile/store";
import findExperts from "../find-experts/store";
import layout from "../layout/store";
import reportProblem from "../report-problem/store";
import settings from "../settings/store";
import tools from "../tools/store";
import ypb from "../ypb/store";

const reducer = combineReducers({
  layout,
  settings,
  ypb,
  findExperts,
  tools,
  expertProfile,
  reportProblem,
});

export default reducer;
