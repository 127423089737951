import { combineReducers } from "@reduxjs/toolkit";
import activeReferrals from "./activeReferralsSlice";
import closedReferrals from "./closedReferralsSlice";
import content from "./contentSlice";
import convertedReferrals from "./convertedReferralsSlice";
import newReferrals from "./newReferralsSlice";

const reducer = combineReducers({
  content,
  newReferrals,
  activeReferrals,
  convertedReferrals,
  closedReferrals,
});

export default reducer;
