import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IExpertProfileContent } from "models/dashboard/consumer/expert-profile";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerExpertProfileContentReq } from "rest-api/dashboard/consumer/expert-profile";

export const getConsumerExpertProfileContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/experts/profile/content/getConsumerExpertProfileContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerExpertProfileContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerExpertProfileContent = ({ dashboard }: RootState) =>
  dashboard.consumer.expertProfile.content;

const initialState: IExpertProfileContent = {
  previousText: "",
  profileSection: {
    items: [],
  },
  aboutSection: {
    title: "",
    values: [],
  },
  detailsSection: {
    title: "",
    values: [],
  },
  reviewsSection: {
    title: "",
    buttons: [],
    allReviews: {
      headerTitle: "",
      title: "",
    },
    filters: [],
  },
  previousWorkSection: {
    title: "",
    viewPreviousWork: {
      headerTitle: "",
    },
  },
  getMatchedSection: {
    content: "",
    button: { text: "", path: "" },
  },
  reportProfileText: "",
  reportedProfileText: "",
};

const contentSlice = createSlice({
  name: "dashboard/consumer/experts/profile/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerExpertProfileContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IExpertProfileContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
