import { combineReducers } from "@reduxjs/toolkit";
import edit from "../edit/store";
import getStarted from "../get-started/store";
import requestAnotherExpert from "../request-another-expert/store";
import select from "../select/store";
import request from "../store/requestSlice";
import content from "./contentSlice";

const reducer = combineReducers({
  content,
  edit,
  getStarted,
  requestAnotherExpert,
  request,
  select,
});

export default reducer;
