import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import {
  IYourExpertsContent,
  IYourExpertsForBuyingContent,
  IYourExpertsForManagingContent,
  IYourExpertsForSellingContent,
} from "models/dashboard/consumer/your-experts";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

export const getConsumerYourExpertsContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-your-expert`, {
      params: {
        populate: {
          noRequest: {
            populate: { icon: imagePopulate },
          },
          pendingRequest: {
            populate: { icon: imagePopulate },
          },
          matchedCard: {
            populate: { buttons: { populate: "*" } },
          },
          forBuying: {
            populate: { items: { populate: "*" } },
          },
          forSelling: {
            populate: { items: { populate: "*" } },
          },
          forManaging: {
            populate: { items: { populate: "*" } },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IYourExpertsContent>)
    .catch(getError);
};

export const getConsumerForBuyingContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-your-expert`, {
      params: {
        populate: {
          forBuying: {
            populate: { items: { populate: "*" } },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IYourExpertsForBuyingContent>)
    .catch(getError);
};

export const getConsumerForSellingContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-your-expert`, {
      params: {
        populate: {
          forSelling: {
            populate: { items: { populate: "*" } },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IYourExpertsForSellingContent>)
    .catch(getError);
};

export const getConsumerForManagingContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-your-expert`, {
      params: {
        populate: {
          forManaging: {
            populate: { items: { populate: "*" } },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IYourExpertsForManagingContent>)
    .catch(getError);
};
