import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectConsumerExpertReviewsFilterState = ({ dashboard }: RootState) =>
  dashboard.consumer.expertProfile.reviewsFilterState;

interface IInitialState {
  activeFilter: string;
}

const initialState: IInitialState = {
  activeFilter: "All",
};

const reviewsFilterStateSlice = createSlice({
  name: "dashboard/consumer/expert-profile/reviews/state",
  initialState,
  reducers: {
    setActiveFilter: (state, action: PayloadAction<IInitialState["activeFilter"]>) => {
      state.activeFilter = action.payload;
    },
  },
});

export const { setActiveFilter } = reviewsFilterStateSlice.actions;

export default reviewsFilterStateSlice.reducer;
