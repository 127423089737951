import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IConsumerDashboardMenus } from "models/dashboard/consumer/layout";
import { Menus } from "models/main/shared";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

const transformMenus = (menus: Menus): IConsumerDashboardMenus => {
  return {
    nav: menus.find((m) => m.slug === "consumer-dashboard") ?? { items: [] },
    mobileNav: menus.find((m) => m.slug === "consumer-dashboard-mobile") ?? { items: [] },
    userMenu: menus.find((m) => m.slug === "consumer-dashboard-user-menu") ?? { items: [] },
    expertsNav: menus.find((m) => m.slug === "consumer-dashboard-experts") ?? { items: [] },
  };
};

export const getConsumerDashboardMenusReq = () => {
  return axiosInstance
    .get(`/api/menus`, {
      params: {
        filters: {
          slug: {
            $in: [
              "consumer-dashboard",
              "consumer-dashboard-mobile",
              "consumer-dashboard-user-menu",
              "consumer-dashboard-experts",
            ],
          },
        },
        fields: ["id", "slug"],
        nested: true,
        populate: { items: { populate: { icon: imagePopulate } } },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<Menus>)
    .then(transformMenus)
    .catch(getError);
};

export const getConsumerDashboardLayoutReq = async () => {};
