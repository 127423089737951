import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectLeaveReviewState = ({ dashboard }: RootState) =>
  dashboard.consumer.expertProfile.leaveReviewState;

interface IInitialState {
  selectedRating: number | null;
  selectedReferralId?: string;
}

const initialState: IInitialState = {
  selectedRating: null,
};

const loginStateSlice = createSlice({
  name: "dashboard/consumer/expert/profile/leaveReview/state",
  initialState,
  reducers: {
    setLeaveReviewState: (_state, action: PayloadAction<IInitialState>) => action.payload,
  },
});

export const { setLeaveReviewState } = loginStateSlice.actions;

export default loginStateSlice.reducer;
