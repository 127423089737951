import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { ILeaveReviewContent } from "models/dashboard/consumer/leave-review";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerLeaveReviewContentReq } from "rest-api/dashboard/consumer/leave-review";

export const getConsumerLeaveReviewContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/expert/profile/leaveReview/content/getConsumerLeaveReviewContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerLeaveReviewContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerLeaveReviewContent = ({ dashboard }: RootState) =>
  dashboard.consumer.expertProfile.leaveReviewContent;

const initialState: ILeaveReviewContent = {
  title: "",
  mainSection: {
    title: "",
    content: "",
    fields: [],
    photosSectionLinkText: "",
    buttons: [],
  },
  photosSection: {
    title: "",
    content: "",
    fields: [],
    buttons: [],
  },
  successSection: {
    icon: { url: "", alternativeText: "" },
    content: "",
    buttons: [],
  },
  discardSection: {
    title: "",
    content: "",
    buttons: [],
  },
};

const contentSlice = createSlice({
  name: "dashboard/consumer/expert/profile/leaveReview/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerLeaveReviewContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<ILeaveReviewContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
