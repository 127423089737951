import { combineReducers } from "@reduxjs/toolkit";
import content from "./contentSlice";
import potentialExperts from "./potentialExpertsSlice";

const reducer = combineReducers({
  content,
  potentialExperts,
});

export default reducer;
