import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IToolsContent } from "models/dashboard/consumer/tools";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerToolsContentReq } from "rest-api/dashboard/consumer/tools";

export const getConsumerToolsContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>("dashboard/consumer/tools/content/getConsumerToolsContentReq", async (_, { rejectWithValue }) => {
  const result = await getConsumerToolsContentReq();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const selectConsumerToolsContent = ({ dashboard }: RootState) =>
  dashboard.consumer.tools.content;

const initialState: IToolsContent = {
  title: "",
  groups: [],
};

const contentSlice = createSlice({
  name: "dashboard/consumer/tools/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerToolsContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IToolsContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
