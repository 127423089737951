import { ButtonProps, Button as MuiButton } from "@mui/material";
import { useUpdatedStyles } from "hooks";
import clsx from "lib/clsx";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { LoadingSpinner } from ".";

interface IProps extends ButtonProps {
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, IProps>(
  ({ className, loading, disabled, variant, children, ...props }, ref) => {
    const internalRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle<typeof internalRef.current, typeof internalRef.current>(
      ref,
      () => internalRef.current,
    );
    const styles = useUpdatedStyles(internalRef);
    const fontSize = styles.fontSize;
    const isDisabled = disabled || loading;

    return (
      <MuiButton
        {...props}
        ref={internalRef}
        className={clsx(
          variant === "outlined" && "border-2 hover:border-2",
          variant === "text" && isDisabled && "pointer-events-none",
          disabled && variant !== "contained" && "!text-disabled",
          className,
        )}
        disabled={variant !== "text" && isDisabled}
        variant={variant}
        startIcon={loading ? <LoadingSpinner size={fontSize} /> : props.startIcon}
      >
        <div className="w-full truncate text-nowrap text-center">{children}</div>
      </MuiButton>
    );
  },
);

export default Button;
Button.displayName = "Button";
