import { combineReducers } from "@reduxjs/toolkit";
import content from "./contentSlice";
import forBuying from "./forBuyingContentSlice";
import forManaging from "./forManagingContentSlice";
import forSelling from "./forSellingContentSlice";
import requests from "./requestsSlice";

const reducer = combineReducers({
  content,
  requests,
  forBuying,
  forSelling,
  forManaging,
});

export default reducer;
