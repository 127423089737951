import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectAuthProfessionalRegisterState = ({ auth }: RootState) =>
  auth.register.professional.state;
export const selectAuthProfessionalRegisterActiveStep = createSelector(
  (state: RootState) => state.auth.register.professional,
  ({ content: { steps, profileCompletionSteps }, state: { activeSection, activeStepId } }) =>
    (activeSection === "steps"
      ? steps
      : activeSection === "fullProfileCompletion"
        ? profileCompletionSteps
        : []
    ).find((s) => s.stepId === activeStepId) ?? steps[0],
);
export const selectAuthProfessionalRegisterActiveSteps = createSelector(
  (state: RootState) => state.auth.register.professional,
  ({
    content: { steps, profileCompletionSteps },
    state: { activeSection, activeStepId, prevStepIds },
  }) =>
    (activeSection === "steps"
      ? steps
      : activeSection === "fullProfileCompletion"
        ? profileCompletionSteps
        : []
    ).filter(
      (s) => !prevStepIds.length || prevStepIds.includes(s.stepId) || s.stepId === activeStepId,
    ),
);

interface IInitialState {
  activeSection:
    | "steps"
    | "verifyEmail"
    | "profileCompletionChoice"
    | "success"
    | "fullProfileCompletion"
    | "profileCreation";
  activeStepId: string;
  prevStepIds: string[];
  nextStepId: string;
  skippedIds: string[];
  registerFormValues: { email: string; password: string };
  shouldSubmit: boolean;
}

const initialState: IInitialState = {
  activeSection: "steps",
  activeStepId: "",
  prevStepIds: [],
  nextStepId: "",
  skippedIds: [],
  registerFormValues: { email: "", password: "" },
  shouldSubmit: false,
};

const registerStateSlice = createSlice({
  name: "auth/register/professional/state",
  initialState,
  reducers: {
    setActiveSection: (state, { payload }: PayloadAction<IInitialState["activeSection"]>) => {
      state.activeSection = payload;
    },
    setActiveStepId: (state, { payload }: PayloadAction<IInitialState["activeStepId"]>) => {
      if (payload < state.activeStepId) {
        const index = state.prevStepIds.indexOf(payload);
        state.prevStepIds = index !== -1 ? state.prevStepIds.slice(0, index) : [];
      }
      state.skippedIds = state.skippedIds.filter((s) => s !== payload);
      state.activeStepId = payload;
    },
    addToPrevStepIds: (state, { payload }: PayloadAction<IInitialState["prevStepIds"]>) => {
      state.prevStepIds.push(...payload);
    },
    setNextStepId: (state, { payload }: PayloadAction<IInitialState["nextStepId"]>) => {
      state.nextStepId = payload;
    },
    addToSkippedStepIds: (state, { payload }: PayloadAction<IInitialState["skippedIds"]>) => {
      state.skippedIds.push(...payload);
    },
    removeFromSkippedStepIds: (state, { payload }: PayloadAction<IInitialState["skippedIds"]>) => {
      state.skippedIds = state.skippedIds.filter((s) => !payload.includes(s));
    },
    setRegisterFormValues: (state, action: PayloadAction<IInitialState["registerFormValues"]>) => {
      state.registerFormValues = action.payload;
    },
    setShouldSubmit: (state, { payload }: PayloadAction<IInitialState["shouldSubmit"]>) => {
      state.shouldSubmit = payload;
    },
  },
});

export const {
  setActiveSection,
  setActiveStepId,
  addToPrevStepIds,
  setNextStepId,
  addToSkippedStepIds,
  removeFromSkippedStepIds,
  setRegisterFormValues,
  setShouldSubmit,
} = registerStateSlice.actions;

export default registerStateSlice.reducer;
