import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import set from "lodash/set";
import { IReferralTransformed } from "models/dashboard/consumer/referral";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { getRelevantReferralsReq } from "rest-api/referral";
import { transformEntityResponse } from "utils/format";

export const getConvertedReferrals = createAsyncThunk<
  { data: IReferralTransformed[]; meta: Pick<EntityListParams, "pagination"> },
  void,
  { state: RootState; rejectValue: ErrorResult }
>(
  "dashboard/professional/referralsBoard/convertedReferrals/getConvertedReferrals",
  async (_, { getState, rejectWithValue }) => {
    const result = await getRelevantReferralsReq(
      "converted",
      selectConvertedReferralsParams(getState()),
    );

    if (isError(result)) {
      return rejectWithValue(result);
    }

    const fieldOptions = selectFieldOptions(getState());
    const transformedData = transformEntityResponse<IReferralTransformed[]>(
      result.data,
      fieldOptions,
    );
    transformedData.map((r) => {
      if (!r.buyerSeller) return;

      const transformedBuyerSellerData = transformEntityResponse<
        IReferralTransformed["buyerSeller"]
      >(r.buyerSeller, fieldOptions);
      set(r, "buyerSeller", transformedBuyerSellerData);

      const formattedExpertQuestionsResult = transformEntityResponse<
        IReferralTransformed["expertQuestions"]
      >(
        r.expertQuestions,
        [...fieldOptions].map((o) => {
          const field = { ...o };
          if (field.name === "locations") {
            field.name = `${r.job}_locations`;
          }
          return field;
        }),
      );
      set(r, "expertQuestions", formattedExpertQuestionsResult);
    });

    return { data: transformedData, meta: result.meta };
  },
);

const convertedReferralsAdapter = createEntityAdapter<IReferralTransformed>({});

export const selectConvertedReferralsData = ({ dashboard }: RootState) =>
  dashboard.professional.referralsBoard.convertedReferrals;

export const {
  selectAll: selectConvertedReferrals,
  selectById: selectConvertedReferralsById,
  selectTotal: selectConvertedReferralsTotal,
} = convertedReferralsAdapter.getSelectors<RootState>(
  ({ dashboard }) => dashboard.professional.referralsBoard.convertedReferrals,
);

export const selectConvertedReferralsMeta = ({ dashboard }: RootState) =>
  dashboard.professional.referralsBoard.convertedReferrals.meta;

export const selectConvertedReferralsParams = createSelector(
  [selectConvertedReferralsData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = convertedReferralsAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: 12 },
  meta: {
    requestStatus: "initial",
  },
});

const convertedReferralsSlice = createSlice({
  name: "dashboard/professional/referralsBoard/convertedReferrals",
  initialState,
  reducers: {
    setConvertedReferralsParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
    removeFromConvertedReferrals: (
      state,
      { payload }: PayloadAction<IReferralTransformed["id"]>,
    ) => {
      convertedReferralsAdapter.removeOne(state, payload);
      if (state.pagination.total) state.pagination.total -= 1;
    },
    resetConvertedReferrals: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConvertedReferrals.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getConvertedReferrals.fulfilled, (state, { payload }) => {
        convertedReferralsAdapter.upsertMany(state, payload.data);
        state.pagination = payload.meta.pagination;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getConvertedReferrals.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const {
  setConvertedReferralsParams,
  removeFromConvertedReferrals,
  resetConvertedReferrals,
} = convertedReferralsSlice.actions;

export default convertedReferralsSlice.reducer;
