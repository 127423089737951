import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import { IUserTransformed } from "models/dashboard/shared";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { getPotentiallyMatchedExpertsReq } from "rest-api/referral";
import { transformEntityResponse } from "utils/format";

export const getPotentiallyMatchedExperts = createAsyncThunk<
  IUserTransformed[],
  { id: string },
  { state: RootState; rejectValue: ErrorResult }
>(
  "dashboard/consumer/findExperts/select/getPotentiallyMatchedExperts",
  async ({ id }, { getState, rejectWithValue }) => {
    const result = await getPotentiallyMatchedExpertsReq(
      id /*selectPotentiallyMatchedExpertsParams(getState())*/,
    );

    if (isError(result)) {
      return rejectWithValue(result);
    }

    const fieldOptions = selectFieldOptions(getState());
    const transformedData = transformEntityResponse<IUserTransformed[]>(result, fieldOptions);

    return transformedData;
  },
);

const potentialExpertsAdapter = createEntityAdapter<IUserTransformed>({});

export const selectPotentiallyMatchedExpertsData = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.select.potentialExperts;

export const {
  selectAll: selectPotentiallyMatchedExperts,
  selectById: selectPotentiallyMatchedExpertsById,
  selectTotal: selectPotentiallyMatchedExpertsTotal,
} = potentialExpertsAdapter.getSelectors<RootState>(
  ({ dashboard }) => dashboard.consumer.findExperts.select.potentialExperts,
);

export const selectPotentiallyMatchedExpertsMeta = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.select.potentialExperts.meta;

export const selectPotentiallyMatchedExpertsParams = createSelector(
  [selectPotentiallyMatchedExpertsData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = potentialExpertsAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: -1 },
  meta: {
    requestStatus: "initial",
  },
});

const potentialExpertsSlice = createSlice({
  name: "dashboard/consumer/findExperts/select",
  initialState,
  reducers: {
    setPotentiallyMatchedExpertsParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPotentiallyMatchedExperts.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getPotentiallyMatchedExperts.fulfilled, (state, action) => {
        potentialExpertsAdapter.removeAll(state); //! Remove this line if paginating. (Use this only if all items are fetched at once)
        potentialExpertsAdapter.upsertMany(state, action.payload);
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getPotentiallyMatchedExperts.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const { setPotentiallyMatchedExpertsParams } = potentialExpertsSlice.actions;

export default potentialExpertsSlice.reducer;
