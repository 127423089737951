import { Add, Clear, ExpandMore, Remove } from "@mui/icons-material";
import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  RadioGroup as MuiRadioGroup,
  Radio,
  Typography,
} from "@mui/material";
import { Button, Icon, InnerHTML } from "components/shared";
import { useDeepCompareEffect } from "hooks";
import clsx from "lib/clsx";
import get from "lodash/get";
import without from "lodash/without";
import { IForm, IRadioGroup, ISelectOption } from "models/form";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDefaultBy, useFilteredSelectOptions } from "./hooks";

interface IItemProps extends Pick<IRadioGroup, "disabled" | "size" | "color" | "variant"> {
  option: ISelectOption;
  value: string;
  expanded: string[];
  setExpanded: Dispatch<SetStateAction<string[]>>;
}

const Item = ({
  option: { name, label, icon },
  disabled,
  size,
  color,
  variant,
  value,
  expanded,
  setExpanded,
}: IItemProps) => {
  const isExpanded = useMemo(() => expanded.some((e) => e.includes(name)), [expanded, name]);
  const isSelected = value === name;

  return (
    <Button
      key={name}
      className={clsx(
        "block h-full text-wrap p-0 text-start normal-case text-light",
        variant === "contained" && "!shadow-inner drop-shadow-md",
      )}
      component="div"
      disabled={disabled}
      disableRipple={variant === "text" || isSelected}
      variant={variant}
      size={size}
      color={color}
      disableElevation
      sx={
        variant === "contained"
          ? {
              backgroundColor: `var(--palette-background-main) !important`,
            }
          : variant === "outlined"
            ? {
                borderRadius: "var(--shape-borderRadius)",
                borderWidth: "2px !important",
                borderColor: `var(--palette-${color}-main)`,
              }
            : variant === "text"
              ? { width: "fit-content", backgroundColor: "transparent !important" }
              : {}
      }
    >
      <FormControlLabel
        className={clsx(
          size === "medium"
            ? variant === "outlined"
              ? "min-h-[4.6rem]"
              : "min-h-[5rem]"
            : "min-h-[4.05rem]",
          variant !== "text" && "px-2",
          disabled && "text-disabled",
          "m-0 w-full text-wrap text-start",
        )}
        classes={{ label: "w-full" }}
        control={
          <Radio
            className={clsx(variant === "text" ? "mr-3 p-0" : "p-2.5")}
            size={size}
            color={color}
            sx={{
              color: disabled
                ? `var(--palette-text-disabled) !important`
                : `var(--palette-${color}-main)`,

              "svg:first-of-type": {
                transform: "scale(1.2)",
              },

              ['&.Mui-checked svg[data-testid="RadioButtonCheckedIcon"]']: {
                transform: "scale(1.4)",
              },
            }}
          />
        }
        label={
          !label ? undefined : (
            <>
              <div className="flex items-center justify-between">
                <div className="mx-1.5">
                  <div className="flex items-center">
                    {icon && (
                      <Icon
                        className="mr-4 flex h-auto w-6 items-center object-contain"
                        icon={icon}
                        sx={{
                          svg: {
                            display: "flex",
                            alignItems: "center",
                            width: "15px",
                            height: "auto",
                          },
                        }}
                      />
                    )}
                    <Typography
                      className="leading-tight duration-150"
                      variant={size === "medium" ? "p2" : "p3"}
                    >
                      {label.primary}
                    </Typography>
                  </div>
                  <Typography className="text-light/80" variant="body2">
                    {label.secondary}
                  </Typography>
                </div>
                {label.content && (
                  <IconButton
                    aria-expanded={isExpanded}
                    aria-label="show more"
                    className={clsx(
                      isExpanded && "-rotate-180",
                      "transition-transform duration-300",
                    )}
                    size="small"
                    onClick={(e) => {
                      setExpanded((prev) =>
                        prev.includes(name) ? without(prev, name) : [...prev, name],
                      );
                    }}
                  >
                    <ExpandMore />
                  </IconButton>
                )}
              </div>
              {label.content && (
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <InnerHTML className="m-1.5 text-light" variant="caption">
                    {label.content}
                  </InnerHTML>
                </Collapse>
              )}
            </>
          )
        }
        value={name}
      />
    </Button>
  );
};

const RadioGroup = ({
  name,
  label,
  helperText,
  required,
  disabled,
  color,
  size,
  icon,
  styles,
  defaultByGroups,
  variant,
  radioGroupOptions,
}: IRadioGroup) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<IForm<string>>();

  const filteredOptions = useFilteredSelectOptions(name, radioGroupOptions) ?? [];
  const options = filteredOptions;

  //#region Set defaultBy
  const defaultByValue = useDefaultBy<string>(defaultByGroups);
  const watchValues = useWatch();
  const currentValue = get(watchValues, name);

  useEffect(() => {
    if (defaultByValue && !currentValue) {
      resetField(name, { defaultValue: defaultByValue, keepDirty: true });
    }
  }, [defaultByValue, currentValue, name, resetField]);
  //#endregion

  const [expanded, setExpanded] = useState<string[]>([]);
  const [expandAll, setExpandAll] = useState(false);
  const optionNames = useMemo(() => options.map((o) => o.name), [options]);
  const error = get(errors, name);

  useDeepCompareEffect(() => {
    if (expandAll) {
      setExpanded(optionNames);
    } else {
      setExpanded([]);
    }
  }, [expandAll, optionNames]);

  useEffect(() => {
    if (expanded.length === optionNames.length) {
      setExpandAll(true);
    } else if (expanded.length === 0) {
      setExpandAll(false);
    }
  }, [expanded, optionNames.length]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { value, ...field } }) => (
        <FormControl
          className="block space-y-4"
          component="fieldset"
          required={required}
          disabled={disabled}
          sx={styles}
        >
          <div className="flex items-center">
            {icon && (
              <Icon
                className="mr-4 flex h-auto w-6 items-center object-contain"
                icon={icon}
                sx={{
                  svg: {
                    display: "flex",
                    alignItems: "center",
                    width: "15px",
                    height: "auto",
                  },
                }}
              />
            )}
            {label && (
              <FormLabel className="flex !text-light" component="legend" error={!!error}>
                <Typography variant="p1">{label}</Typography>
              </FormLabel>
            )}
            <div className="ml-auto space-x-2">
              {!required && value && (
                <Button
                  className="h-12 px-5 text-2xl"
                  size="small"
                  endIcon={<Clear />}
                  onClick={() => {
                    resetField(name);
                  }}
                >
                  Clear
                </Button>
              )}
              {options.some((o) => o.label?.content) && (
                <Button
                  classes={{
                    endIcon: clsx(expandAll && "rotate-90", "transition-transform duration-300"),
                  }}
                  size="small"
                  endIcon={expandAll ? <Remove className="rotate-90" /> : <Add />}
                  onClick={() => {
                    setExpandAll((prev) => !prev);
                  }}
                >
                  {expandAll ? "Collapse" : "Expand"} all
                </Button>
              )}
            </div>
          </div>
          <MuiRadioGroup
            aria-label={label}
            className={clsx(variant !== "text" && "gap-[16px]")}
            {...field}
            value={value}
          >
            {options?.map((o) => (
              <Item
                key={o.name}
                option={o}
                disabled={disabled}
                size={size}
                color={color}
                variant={variant}
                value={value}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            ))}
            {(error || helperText) && (
              <FormHelperText className="mx-0 !mt-2 text-light" error={!!error}>
                {error?.message || helperText}
              </FormHelperText>
            )}
          </MuiRadioGroup>
        </FormControl>
      )}
    />
  );
};

export default RadioGroup;
