import { FormField, IFormComponent, ISelectOption } from "models/form";
import { IImage } from "models/shared";
import { IReferralTransformed } from "./consumer/referral";

export interface IAvatarUser {
  firstName: string;
  lastName: string;
  profilePicture?: IImage;
}

export const userKeys = [
  "firstName",
  "lastName",
  "profilePicture",
  "phone",
  "dateOfBirth",
  "locations",
  "suburbs",
  "professional.businessName",
  "professional.job",
  "professional.certificate",
  "professional.publicLiabilityInsurance",
  "professional.publicLiabilityExpirationDate",
  "professional.publicLiabilityPolicyNumber",
  "professional.abn",
  "buyerSeller.goal",
  "buyerSeller.propertyType",
  "buyerSeller.timeframe",
] as const;

export interface IReview {
  id: string;
  hidden: boolean;
  rating: number;
  reviewContent: string;
  images: IImage[];
  createdAt: string;
  referral?: IReferralTransformed;
}

export interface IPreviousWork {
  title: string;
  content: string;
  images?: IImage[];
}

export interface IUser {
  id: string;
  questionnaireComplete: boolean;
  role: { name: "Consumer" | "Professional" | null };
  confirmed: boolean;
  email: string;
  firstName: string;
  lastName: string;
  profilePicture?: IImage;
  phone: string;
  locations: string[];
  suburbs: string[];
  newsletterSubscribed?: boolean;
  invoiceDue?: boolean;
  professional?: {
    verified: boolean;
    businessName: string;
    job: string;
    certificate: string;
    publicLiabilityInsurance: string;
    publicLiabilityExpirationDate: string;
    publicLiabilityPolicyNumber: string;
    abn: string;
    previousWork: IPreviousWork[];
  };
  buyerSeller?: {
    goal: string[];
    propertyType: string;
    timeframe: string;
  };
  commercialEmailsUnsubscribed: boolean;
  reviews: IReview[];
}

export interface IUserTransformed
  extends Omit<IUser, "locations" | "suburbs" | "professional" | "buyerSeller"> {
  locations: ISelectOption[];
  suburbs: ISelectOption[];
  professional?: Omit<
    NonNullable<IUser["professional"]>,
    "job" | "certificate" | "publicLiabilityInsurance"
  > & {
    job: ISelectOption;
    certificate: ISelectOption;
    publicLiabilityInsurance: ISelectOption;
  };
  buyerSeller?: {
    goal: ISelectOption;
    propertyType: ISelectOption;
    timeframe: ISelectOption;
  };
}

export interface IDashboardLayout {
  footer: {
    content: string;
    copyright: string;
  };
}

export interface IHeaderSection {
  title: string;
  content?: string;
  icon: IImage;
}

//#region Settings
export interface IFormStep {
  title: string;
  content: string;
  fields: IFormComponent["fields"];
  contentAfterFields: string;
  submitButtonText?: IFormComponent["submitButtonText"];
}

type FormType =
  | "ComponentFormStepText"
  | "ComponentFormStepSelect"
  | "ComponentFormStepEmail"
  | "ComponentFormStepPhone"
  | "ComponentFormStepDate";

type FormItem = Pick<
  FormField,
  | "id"
  | "label"
  | "helperText"
  | "required"
  | "disabled"
  | "width"
  | "color"
  | "visibleByGroups"
  | "visible"
> & {
  formType: FormType;
  identifier?: string;
  value: string;
  valueFields: FormField[];
  steps: IFormStep[];
};

export type ValueFieldWithFormType = Omit<FormItem, "valueFields"> & {
  names: string[];
};

export type ValueField = Omit<ValueFieldWithFormType, "visible" | "visibleByGroups">;

export type DetailsSection = {
  title: string;
  content?: string;
  forms: FormItem[];
};

export type ProfileSection = { completionFields: { name: string }[]; items: { value: string }[] };
//#endregion
