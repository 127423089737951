import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IToolsContent } from "models/dashboard/consumer/tools";
import { imagePopulate } from "models/shared";
import { transformResponse } from "utils/format";

export const getConsumerToolsContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-tool`, {
      params: {
        populate: {
          groups: {
            populate: {
              cards: {
                populate: { icon: imagePopulate },
              },
            },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IToolsContent>)
    .catch(getError);
};
