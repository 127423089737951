import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import set from "lodash/set";
import { IReferralTransformed } from "models/dashboard/consumer/referral";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { getRelevantReferralsReq } from "rest-api/referral";
import { transformEntityResponse } from "utils/format";

export const getActiveReferrals = createAsyncThunk<
  { data: IReferralTransformed[]; meta: Pick<EntityListParams, "pagination"> },
  void,
  { state: RootState; rejectValue: ErrorResult }
>(
  "dashboard/professional/referralsBoard/activeReferrals/getActiveReferrals",
  async (_, { getState, rejectWithValue }) => {
    const result = await getRelevantReferralsReq("active", selectActiveReferralsParams(getState()));

    if (isError(result)) {
      return rejectWithValue(result);
    }

    const fieldOptions = selectFieldOptions(getState());
    const transformedData = transformEntityResponse<IReferralTransformed[]>(
      result.data,
      fieldOptions,
    );
    transformedData.map((r) => {
      if (!r.buyerSeller) return;

      const transformedBuyerSellerData = transformEntityResponse<
        IReferralTransformed["buyerSeller"]
      >(r.buyerSeller, fieldOptions);
      set(r, "buyerSeller", transformedBuyerSellerData);

      const formattedExpertQuestionsResult = transformEntityResponse<
        IReferralTransformed["expertQuestions"]
      >(
        r.expertQuestions,
        [...fieldOptions].map((o) => {
          const field = { ...o };
          if (field.name === "locations") {
            field.name = `${r.job}_locations`;
          }
          return field;
        }),
      );
      set(r, "expertQuestions", formattedExpertQuestionsResult);
    });

    return { data: transformedData, meta: result.meta };
  },
);

const activeReferralsAdapter = createEntityAdapter<IReferralTransformed>({});

export const selectActiveReferralsData = ({ dashboard }: RootState) =>
  dashboard.professional.referralsBoard.activeReferrals;

export const {
  selectAll: selectActiveReferrals,
  selectById: selectActiveReferralsById,
  selectTotal: selectActiveReferralsTotal,
} = activeReferralsAdapter.getSelectors<RootState>(
  ({ dashboard }) => dashboard.professional.referralsBoard.activeReferrals,
);

export const selectActiveReferralsMeta = ({ dashboard }: RootState) =>
  dashboard.professional.referralsBoard.activeReferrals.meta;

export const selectActiveReferralsParams = createSelector(
  [selectActiveReferralsData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = activeReferralsAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: 12 },
  meta: {
    requestStatus: "initial",
  },
});

const activeReferralsSlice = createSlice({
  name: "dashboard/professional/referralsBoard/activeReferrals",
  initialState,
  reducers: {
    setActiveReferralsParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
    removeFromActiveReferrals: (state, { payload }: PayloadAction<IReferralTransformed["id"]>) => {
      activeReferralsAdapter.removeOne(state, payload);
      if (state.pagination.total) state.pagination.total -= 1;
    },
    resetActiveReferrals: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveReferrals.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getActiveReferrals.fulfilled, (state, { payload }) => {
        activeReferralsAdapter.upsertMany(state, payload.data);
        state.pagination = payload.meta.pagination;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getActiveReferrals.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const { setActiveReferralsParams, removeFromActiveReferrals, resetActiveReferrals } =
  activeReferralsSlice.actions;

export default activeReferralsSlice.reducer;
