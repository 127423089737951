import { AnimatePresence, m } from "framer-motion";
import { useAppSelector } from "lib/redux";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { selectSharedLayoutContent } from "../../store/layoutContentSlice";

interface IProps {
  children: ReactNode;
  className?: string;
  enablePageTransition?: boolean;
}

const SharedTransition = ({ children, className, enablePageTransition }: IProps) => {
  const {
    settings: { enablePageTransition: enablePageTransitionGlobally },
  } = useAppSelector(selectSharedLayoutContent);

  const { pathname } = useRouter();

  if ((!enablePageTransitionGlobally && !enablePageTransition) || enablePageTransition === false) {
    return children;
  }

  return (
    <AnimatePresence mode="wait">
      <m.div
        key={pathname}
        className={className}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </m.div>
    </AnimatePresence>
  );
};

export default SharedTransition;
