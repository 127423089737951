import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IProfessionalReferralsBoardContent } from "models/dashboard/professional/referrals-board";
import { HYDRATE } from "next-redux-wrapper";
import { getProfessionalReferralsBoardContentReq } from "rest-api/dashboard/professional/referrals-board";

export const getProfessionalReferralsBoardContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/professional/referralsBoard/content/getProfessionalReferralsBoardContent",
  async (_, { rejectWithValue }) => {
    const result = await getProfessionalReferralsBoardContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectProfessionalReferralsBoardContent = ({ dashboard }: RootState) =>
  dashboard.professional.referralsBoard.content;

const initialState: IProfessionalReferralsBoardContent = {
  tabs: [],
  colors: [],
  form: {
    fields: [],
  },
  referralItem: {
    sections: [],
    content: "",
    buttons: [],
  },
  states: [],
};

const contentSlice = createSlice({
  name: "dashboard/professional/referralsBoard/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfessionalReferralsBoardContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IProfessionalReferralsBoardContent, typeof HYDRATE>) =>
          action.payload,
      );
  },
});

export default contentSlice.reducer;
