import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IYourExpertsForManagingContent } from "models/dashboard/consumer/your-experts";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerForManagingContentReq } from "rest-api/dashboard/consumer/your-experts";

export const getConsumerForManagingContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/ypb/forManaging/content/getConsumerForManagingContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerForManagingContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerForManagingContent = ({ dashboard }: RootState) =>
  dashboard.consumer.ypb.forManaging;

const initialState: IYourExpertsForManagingContent = {
  forManaging: [],
};

const contentSlice = createSlice({
  name: "dashboard/consumer/ypb/forManaging/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerForManagingContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IYourExpertsForManagingContent, typeof HYDRATE>) =>
          action.payload,
      );
  },
});

export default contentSlice.reducer;
