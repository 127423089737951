import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { selectProfileData } from "components/dashboard/store/profileSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import { IReferralTransformed } from "models/dashboard/consumer/referral";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { getReferralsReq } from "rest-api/referral";
import { transformEntityResponse } from "utils/format";

export const getRequests = createAsyncThunk<
  IReferralTransformed[],
  void,
  { state: RootState; rejectValue: ErrorResult }
>("dashboard/consumer/ypb/requests/getRequests", async (_, { getState, rejectWithValue }) => {
  const { id } = selectProfileData(getState());
  const result = await getReferralsReq(id, selectRequestsParams(getState()));

  if (isError(result)) {
    return rejectWithValue(result);
  }

  const fieldOptions = selectFieldOptions(getState());
  const fieldOptionsFormatted = fieldOptions.map((r) => ({
    ...r,
    name: r.name.split(".").at(-1)!,
  }));

  const formattedResult = transformEntityResponse<IReferralTransformed[]>(
    result,
    fieldOptionsFormatted,
  );

  return formattedResult;
});

const requestsAdapter = createEntityAdapter<IReferralTransformed>({});

export const selectRequestsData = ({ dashboard }: RootState) => dashboard.consumer.ypb.requests;

export const {
  selectAll: selectRequests,
  selectById: selectRequestsById,
  selectTotal: selectRequestsTotal,
} = requestsAdapter.getSelectors<RootState>(({ dashboard }) => dashboard.consumer.ypb.requests);

export const selectRequestsMeta = ({ dashboard }: RootState) =>
  dashboard.consumer.ypb.requests.meta;

export const selectRequestsParams = createSelector(
  [selectRequestsData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = requestsAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: -1 },
  meta: {
    requestStatus: "initial",
  },
});

const requestsSlice = createSlice({
  name: "dashboard/consumer/ypb/requests",
  initialState,
  reducers: {
    setRequestsParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequests.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getRequests.fulfilled, (state, action) => {
        requestsAdapter.removeAll(state); //! Remove this line if paginating. (Use this only if all items are fetched at once)
        requestsAdapter.upsertMany(state, action.payload);
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getRequests.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const { setRequestsParams } = requestsSlice.actions;

export default requestsSlice.reducer;
