import { StarOutline } from "@mui/icons-material";
import { Box, FormHelperText, InputLabel, Rating, Typography } from "@mui/material";
import get from "lodash/get";
import { IForm, IRatingField } from "models/form";
import { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDefaultBy } from "./hooks";

const RatingField = ({
  name,
  label,
  required,
  disabled,
  color,
  size,
  styles,
  defaultByGroups,
  readOnly,
  precision,
}: IRatingField) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<IForm<number | null>>();

  //#region Set defaultBy
  const defaultByValue = useDefaultBy<number>(defaultByGroups);
  const watchValues = useWatch();
  const currentValue = get(watchValues, name);

  useEffect(() => {
    if (defaultByValue && currentValue === null) {
      resetField(name, { defaultValue: defaultByValue, keepDirty: true });
    }
  }, [defaultByValue, currentValue, name, resetField]);
  //#endregion

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field: { onChange, ...field } }) => (
        <Box className="space-y-4" sx={styles}>
          {label && (
            <InputLabel className="flex text-wrap text-light" htmlFor={name} required={required}>
              <Typography variant="p1">{label}</Typography>
            </InputLabel>
          )}
          <Rating
            {...field}
            disabled={disabled}
            size={size}
            readOnly={readOnly}
            precision={precision}
            emptyIcon={<StarOutline color={color} fontSize="inherit" />}
            onChange={(_, newValue) => {
              onChange(newValue);
            }}
            sx={{
              fontSize: size === "medium" ? "45px" : "35px",
            }}
          />
          {errors[name] && (
            <FormHelperText error variant="standard">
              {errors[name]?.message}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default RatingField;
