import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { showMessage } from "components/shared/layout/store/messageSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { AppDispatch, RootState } from "lib/redux";
import { IExpertProfile } from "models/dashboard/consumer/expert-profile";
import { RequestMeta } from "models/shared";
import { HYDRATE } from "next-redux-wrapper";
import { getExpertProfileReq } from "rest-api/dashboard/consumer/expert-profile";
import { transformEntityResponse } from "utils/format";

export const getExpertProfile = createAsyncThunk<
  { data: IExpertProfile },
  { id: string },
  { dispatch: AppDispatch; state: RootState; rejectValue: ErrorResult }
>(
  "dashboard/consumer/experts/profile/getExpertProfile",
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    const result = await getExpertProfileReq(id);

    if (isError(result)) {
      dispatch(showMessage({ message: result.error.message, variant: "error" }));
      return rejectWithValue(result);
    }

    const fieldOptions = selectFieldOptions(getState());
    const transformedData = transformEntityResponse<IExpertProfile>(result, fieldOptions);

    return { data: transformedData };
  },
);

export const selectExpertProfile = ({ dashboard }: RootState) =>
  dashboard.consumer.expertProfile.profile;

export const selectExpertProfileData = ({ dashboard }: RootState) =>
  dashboard.consumer.expertProfile.profile.data;

export const selectExpertProfileMeta = ({ dashboard }: RootState) =>
  dashboard.consumer.expertProfile.profile.meta;

interface IInitialState extends RequestMeta {
  data: IExpertProfile;
}

const initialData: IExpertProfile = {
  id: "",
  confirmed: true,
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  locations: [],
  suburbs: [],
  reviews: [],
};

const initialState: IInitialState = {
  data: initialData,
  meta: {
    requestStatus: "initial",
  },
};

const profileSlice = createSlice({
  name: "dashboard/consumer/experts/profile",
  initialState,
  reducers: {
    resetExpertProfile: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpertProfile.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getExpertProfile.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getExpertProfile.rejected, (state, action) => {
        state.meta = { requestStatus: "rejected", ...action.payload };
      })
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IInitialState, typeof HYDRATE>) => action.payload,
      );
  },
});

export const { resetExpertProfile } = profileSlice.actions;

export default profileSlice.reducer;
