import { IFileUploadValue } from "components/shared/form/hooks/useFormSubmit";
import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { ILeaveReviewContent } from "models/dashboard/consumer/leave-review";
import { formPopulate } from "models/form";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getConsumerLeaveReviewContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-leave-review`, {
      params: {
        populate: {
          mainSection: { populate: { ...formPopulate["form"]["populate"], buttons: true } },
          photosSection: { populate: { ...formPopulate["form"]["populate"], buttons: true } },
          successSection: { populate: { icon: imagePopulate, buttons: true } },
          discardSection: { populate: { buttons: true } },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<ILeaveReviewContent>)
    .then((data) =>
      transformForm(data, [
        { path: "mainSection", fieldsKey: "fields" },
        { path: "photosSection", fieldsKey: "fields" },
      ]),
    )
    .catch(getError);
};

export async function createReview(data: {
  rating: number;
  reviewContent: string;
  images: IFileUploadValue;
  referral: string;
}): Promise<{ id: string } | ErrorResult> {
  return axiosInstance
    .post<ReturnType<typeof createReview>>(`/api/reviews`, { data }, { withCredentials: true })
    .then(({ data }) => data)
    .catch(getError);
}
