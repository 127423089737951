import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import set from "lodash/set";
import { IReferralTransformed } from "models/dashboard/consumer/referral";
import { EntityListParams, PartialEntityListParams, RequestMeta } from "models/shared";
import { getRelevantReferralsReq } from "rest-api/referral";
import { transformEntityResponse } from "utils/format";

export const getNewReferrals = createAsyncThunk<
  { data: IReferralTransformed[]; meta: Pick<EntityListParams, "pagination"> },
  void,
  { state: RootState; rejectValue: ErrorResult }
>(
  "dashboard/professional/referralsBoard/newReferrals/getNewReferrals",
  async (_, { getState, rejectWithValue }) => {
    const result = await getRelevantReferralsReq("new", selectNewReferralsParams(getState()));

    if (isError(result)) {
      return rejectWithValue(result);
    }

    const fieldOptions = selectFieldOptions(getState());
    const transformedData = transformEntityResponse<IReferralTransformed[]>(
      result.data,
      fieldOptions,
    );
    transformedData.map((r) => {
      if (!r.buyerSeller) return;

      const transformedBuyerSellerData = transformEntityResponse<
        IReferralTransformed["buyerSeller"]
      >(r.buyerSeller, fieldOptions);
      set(r, "buyerSeller", transformedBuyerSellerData);

      const formattedExpertQuestionsResult = transformEntityResponse<
        IReferralTransformed["expertQuestions"]
      >(
        r.expertQuestions,
        [...fieldOptions].map((o) => {
          const field = { ...o };
          if (field.name === "locations") {
            field.name = `${r.job}_locations`;
          }
          return field;
        }),
      );
      set(r, "expertQuestions", formattedExpertQuestionsResult);
    });

    return { data: transformedData, meta: result.meta };
  },
);

const newReferralsAdapter = createEntityAdapter<IReferralTransformed>({});

export const selectNewReferralsData = ({ dashboard }: RootState) =>
  dashboard.professional.referralsBoard.newReferrals;

export const { selectAll: selectNewReferrals, selectById: selectNewReferralsById } =
  newReferralsAdapter.getSelectors<RootState>(
    ({ dashboard }) => dashboard.professional.referralsBoard.newReferrals,
  );

export const selectNewReferralsMeta = ({ dashboard }: RootState) =>
  dashboard.professional.referralsBoard.newReferrals.meta;

export const selectNewReferralsParams = createSelector(
  [selectNewReferralsData],
  ({ search, pagination }) => ({
    search,
    pagination,
  }),
);

const initialState = newReferralsAdapter.getInitialState<RequestMeta & EntityListParams>({
  search: null,
  pagination: { start: 0, limit: 12 },
  meta: {
    requestStatus: "initial",
  },
});

const newReferralsSlice = createSlice({
  name: "dashboard/professional/referralsBoard/newReferrals",
  initialState,
  reducers: {
    setNewReferralsParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
    removeFromNewReferrals: (state, { payload }: PayloadAction<IReferralTransformed["id"]>) => {
      newReferralsAdapter.removeOne(state, payload);
      if (state.pagination.total) state.pagination.total -= 1;
    },
    resetNewReferrals: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewReferrals.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getNewReferrals.fulfilled, (state, { payload }) => {
        newReferralsAdapter.upsertMany(state, payload.data);
        state.pagination = payload.meta.pagination;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getNewReferrals.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const { setNewReferralsParams, removeFromNewReferrals, resetNewReferrals } =
  newReferralsSlice.actions;

export default newReferralsSlice.reducer;
