import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IReportProblemContent } from "models/dashboard/consumer/report-problem";
import { formPopulate } from "models/form";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getConsumerReportProblemContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-report-problem`, {
      params: {
        populate: {
          ...formPopulate,
          contactUsButton: { populate: true },
          successSection: { populate: { icon: imagePopulate, buttons: true } },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IReportProblemContent>)
    .then((data) => transformForm(data, ["form"]))
    .catch(getError);
};

export async function reportProblem(data: {
  reportProblemContent: string;
}): Promise<{ id: string } | ErrorResult> {
  return axiosInstance
    .post<ReturnType<typeof reportProblem>>(
      `/api/report-problems`,
      { data },
      { withCredentials: true },
    )
    .then(({ data }) => data)
    .then(transformResponse<{ id: string }>)
    .catch(getError);
}
