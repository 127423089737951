import { IFileUploadValue } from "components/shared/form/hooks/useFormSubmit";
import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import { IConsumerSettingsContent } from "models/dashboard/consumer/settings";
import { IProfessionalSettingsContent } from "models/dashboard/professional/settings";
import { DetailsSection, IUser } from "models/dashboard/shared";
import { formPopulate } from "models/form";
import { transformForm, transformResponse } from "utils/format";

const detailsSectionPopulate = {
  populate: {
    forms: {
      populate: {
        visibleByGroups:
          formPopulate["form"]["populate"]["fields"]["populate"]["field"]["populate"][
            "visibleByGroups"
          ],
        valueFields: formPopulate["form"]["populate"]["fields"],
        steps: { populate: formPopulate["form"]["populate"] },
      },
    },
  },
};

const transformDetailsSection = (data: any, sectionKey: keyof IProfessionalSettingsContent) => [
  ...(data[sectionKey] as DetailsSection)?.forms?.map((_, i) => ({
    path: `${sectionKey}.forms[${i}]`,
    fieldsKey: "valueFields",
  })),
  ...(data[sectionKey] as DetailsSection)?.forms?.flatMap((f, i) =>
    f.steps.map((_, j) => `${sectionKey}.forms[${i}].steps[${j}]`),
  ),
];

export const getProfessionalSettingsContentReq = () => {
  return axiosInstance
    .get(`/api/professional-setting`, {
      params: {
        populate: {
          sections: true,
          profileSection: {
            populate: {
              completionFields: formPopulate["form"]["populate"]["fields"],
              items: true,
            },
          },
          personalDetailsSection: detailsSectionPopulate,
          businessDetailsSection: detailsSectionPopulate,
          securitySection: {
            populate: { ...formPopulate, links: true },
          },
          preferenceSection: {
            populate: { options: true },
          },
          accountSection: {
            populate: { button: true },
          },
          profileDetailsSection: detailsSectionPopulate,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IProfessionalSettingsContent>)
    .then((data) =>
      transformForm(data, [
        { path: "profileSection", fieldsKey: "completionFields" },
        ...transformDetailsSection(data, "personalDetailsSection"),
        ...transformDetailsSection(data, "businessDetailsSection"),
        ...transformDetailsSection(data, "profileDetailsSection"),
        "securitySection.form",
      ]),
    )
    .catch(getError);
};

export const getConsumerSettingsContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-setting`, {
      params: {
        populate: {
          sections: true,
          profileSection: {
            populate: {
              completionFields: formPopulate["form"]["populate"]["fields"],
              items: true,
            },
          },
          personalDetailsSection: detailsSectionPopulate,
          securitySection: {
            populate: { ...formPopulate, links: true },
          },
          preferenceSection: {
            populate: { options: true },
          },
          accountSection: {
            populate: { dialog: true },
          },
          profileDetailsSection: detailsSectionPopulate,
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IConsumerSettingsContent>)
    .then((data) =>
      transformForm(data, [
        { path: "profileSection", fieldsKey: "completionFields" },
        ...transformDetailsSection(data, "personalDetailsSection"),
        ...transformDetailsSection(data, "profileDetailsSection"),
        "securitySection.form",
      ]),
    )
    .catch(getError);
};

export async function sendChangeEmailOTP(
  data: Pick<IUser, "email"> & { password: string },
): Promise<{ sent: boolean } | ErrorResult> {
  return axiosInstance
    .post<ReturnType<typeof sendChangeEmailOTP>>("/api/auth/change-email", data, {
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export async function verifyChangeEmailOTP(
  data: Pick<IUser, "email"> & { password: string; code: string },
): Promise<IUser | ErrorResult> {
  return axiosInstance
    .post<IUser>("/api/auth/change-email-verify", data, {
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export async function sendChangePhoneOTP(
  data: Pick<IUser, "phone">,
): Promise<{ sent: boolean } | ErrorResult> {
  return axiosInstance
    .post<ReturnType<typeof sendChangePhoneOTP>>("/api/users/me/change-phone", data, {
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export async function verifyChangePhoneOTP(
  data: Pick<IUser, "phone"> & { code: string },
): Promise<IUser | ErrorResult> {
  return axiosInstance
    .post<IUser>("/api/users/me/change-phone-verify", data, {
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export async function updateUserProfile(
  data: Partial<Omit<IUser, "profilePicture"> & { profilePicture?: IFileUploadValue }>,
): Promise<IUser | ErrorResult> {
  return axiosInstance
    .put<IUser>("/api/users/me", data, {
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export async function subscribeNewsletterDOI(
  data: Pick<IUser, "email">,
): Promise<string | ErrorResult> {
  return axiosInstance
    .post<string>("/api/email/subscribe-newsletter-doi", data)
    .then(({ data }) => data)
    .catch(getError);
}

export async function subscribeNewsletter(): Promise<"ok" | ErrorResult> {
  return axiosInstance
    .post<"ok">("/api/email/subscribe-newsletter", {}, { withCredentials: true })
    .then(({ data }) => data)
    .catch(getError);
}

export async function unsubscribeNewsletter(): Promise<"ok" | ErrorResult> {
  return axiosInstance
    .post<"ok">("/api/email/unsubscribe-newsletter", {}, { withCredentials: true })
    .then(({ data }) => data)
    .catch(getError);
}
