import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IReportProfileContent } from "models/dashboard/consumer/expert-profile";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerReportProfileContentReq } from "rest-api/dashboard/consumer/expert-profile";

export const getConsumerReportProfileContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/expert/profile/reportProfile/content/getConsumerReportProfileContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerReportProfileContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerReportProfileContent = ({ dashboard }: RootState) =>
  dashboard.consumer.expertProfile.reportProfileContent;

const initialState: IReportProfileContent = {
  title: "",
  content: "",
  form: { fields: [], submitButtonText: "" },
  contactUsButton: {
    path: "",
    text: "",
  },
  successSection: {
    icon: { url: "", alternativeText: "" },
    content: "",
    buttons: [],
  },
};

const contentSlice = createSlice({
  name: "dashboard/consumer/expert/profile/reportProfile/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerReportProfileContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IReportProfileContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
