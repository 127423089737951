import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { selectFieldOptions } from "components/dashboard/store/fieldOptionsSlice";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import merge from "lodash/merge";
import set from "lodash/set";
import { IReferral, IReferralTransformed } from "models/dashboard/consumer/referral";
import { PartialEntityListParams, RequestMeta } from "models/shared";
import { getReferralReq } from "rest-api/referral";
import { transformEntityResponse } from "utils/format";

export const getRequest = createAsyncThunk<
  { data: IReferral; transformedData: IReferralTransformed } | undefined,
  { id: string },
  { state: RootState; rejectValue: ErrorResult }
>(
  "dashboard/consumer/findExperts/request/getRequest",
  async ({ id }, { getState, rejectWithValue }) => {
    const result = await getReferralReq(id);

    if (isError(result)) {
      return rejectWithValue(result);
    }

    if (!result) {
      return result;
    }

    const fieldOptions = selectFieldOptions(getState());
    const fieldOptionsFormatted = fieldOptions.map((r) => ({
      ...r,
      name: r.name.split(".").at(-1)!,
    }));

    const transformedData = transformEntityResponse<IReferralTransformed>(
      result,
      fieldOptionsFormatted,
    );
    const formattedExpertQuestionsResult = transformEntityResponse<
      IReferralTransformed["expertQuestions"]
    >(result.expertQuestions, fieldOptionsFormatted);
    set(transformedData, "expertQuestions", formattedExpertQuestionsResult);

    return { data: result, transformedData };
  },
);

export const selectRequest = ({ dashboard }: RootState) => dashboard.consumer.findExperts.request;

export const selectRequestData = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.request.data;

export const selectRequestDataTransformed = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.request.transformedData;

export const selectRequestMeta = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.request.meta;

interface IInitialState extends RequestMeta {
  data: IReferral;
  transformedData: IReferralTransformed;
}

const initialData: IReferral = {
  id: "",
  job: "",
  expertQuestions: {},
  status: "unmatched",
  acquisitionStatus: "new",
  createdAt: "",
  relatedReferrals: [],
};

const initialState: IInitialState = {
  data: initialData,
  transformedData: {
    id: "",
    job: { name: "" },
    expertQuestions: {},
    status: "unmatched",
    acquisitionStatus: "new",
    createdAt: "",
  },
  meta: {
    requestStatus: "initial",
  },
};

const requestSlice = createSlice({
  name: "dashboard/consumer/findExperts/request",
  initialState,
  reducers: {
    setRequestsParams: (state, action: PayloadAction<PartialEntityListParams>) => {
      merge(state, action.payload);
    },
    resetRequest: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequest.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getRequest.fulfilled, (state, { payload }) => {
        if (payload) {
          state.data = payload.data;
          state.transformedData = payload.transformedData;
        }
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getRequest.rejected, (state, action) => {
        state.meta = {
          requestStatus: "rejected",
          ...action.payload,
        };
      });
  },
});

export const { setRequestsParams, resetRequest } = requestSlice.actions;

export default requestSlice.reducer;
